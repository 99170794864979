import React from "react";

import { Fade } from "react-awesome-reveal";
import Marquee from "react-fast-marquee";
import { determineCompany } from "../../utils/icons";

const partnersList = [
  "aces",
  "aquia",
  "bah",
  "barbaricum",
  "bluevoyant",
  "boeing",
  "f9",
  "fearless",
  "graydigital",
  "inovex",
  "jacobs",
  "leidos",
  "lockheed",
  "mantech",
  "maxar",
  "ncyber",
  "nvidia",
  "oddball",
  "omni",
  "onyxpoint",
  "oteemo",
  "parsons",
  "raytheon",
  "rebellion",
  "revacomm",
  "rise8",
  "texasam",
  "visionist",
  "vivsoft",
  "vmware",
  "wwt",
];

export default function PartnersSection() {
  return (
    <section className="text-center text-copy-primary p-6 lg:py-20">
      <Fade direction="down" triggerOnce>
        <h2 className="text-3xl lg:text-4xl font-semibold text-center xl:text-5xl 2xl:mt-12 mt-8">
          Our Partners
        </h2>
      </Fade>
      <p className="mt-6 leading-relaxed text-lg  max-w-6xl lg:mx-auto">
        Clarity chooses to forge connections with companies whose values and
        methods align with ours, reflecting the need for digital transformation
        and automation within our customers’ ecosystems. Our partners
        demonstrate a focus on delivering secure, reliable, and resilient
        products that end users love through Human-Centered Design and Agile
        delivery methods. Because harmony between contractors is essential to
        effective delivery, we promote a “badgeless” approach where ideas and
        talent are valued above logos. We are proud to partner with many
        companies in our sector and continue to grow our connections and foster
        long-term relationships
      </p>
      <p className="mt-6 leading-relaxed text-lg  max-w-6xl lg:mx-auto">
        As members of the Digital Services Coalition (DSC), a group of small
        businesses with demonstrated digital transformation practices, Clarity
        has strong connections to like-minded small businesses.
      </p>
      <div className="mt-16 h-full marquee">
        <Marquee direction="left" gradient={false}>
          {partnersList.map((company) => (
            <span key={company} className="w-auto m-10">
              {determineCompany(company)}
            </span>
          ))}
        </Marquee>
      </div>
    </section>
  );
}

import React from "react";
import BImage from "gatsby-background-image";
import Loadable from "@loadable/component";

import Layout from "../components/common/layout";
import SEO from "../components/common/seo";
import useImage from "../hooks/use-image";
import PartnersSection from "../components/about/partnersSection";
import Readmore from "../components/common/readMore";

const MissionSection = Loadable(
  () => import("../components/about/missionSection"),
  {
    fallback: <div>Loading...</div>,
  }
);
const LeadershipSection = Loadable(
  () => import("../components/about/leadershipSection"),
  {
    fallback: <div>Loading...</div>,
  }
);
const CreedSection = Loadable(
  () => import("../components/about/creedSection"),
  {
    fallback: <div>Loading...</div>,
  }
);

const About = () => {
  const { aboutHeroSM, aboutHeroMD, aboutHeroLG, aboutHeroXL } = useImage();
  const auBgs = [
    aboutHeroSM.sharp.fluid,
    {
      ...aboutHeroMD.sharp.fluid,
      media: `(min-width: 500px)`,
    },
    {
      ...aboutHeroLG.sharp.fluid,
      media: `(min-width: 1024px)`,
    },
    {
      ...aboutHeroXL.sharp.fluid,
      media: `(min-width: 1200px)`,
    },
  ];

  return (
    <Layout>
      <SEO
        title="About Us"
        description=" Our principles of servant leadership, mutual respect, and technological excellence enable us to thrive on the challenges of the digital frontline."
      />
      <BImage
        fluid={auBgs}
        Tag="section"
        className="about-us-hero lg:pl-20 lg:pt-24"
      >
        <div className="p-6 py-12 relative z-10" id="story">
          <div className="text-white max-w-full lg:max-w-3xl leading-relaxed">
            <h1 className="text-xl font-medium 2xl:text-2xl">About Us</h1>
            <h2 className="uppercase font-bold text-4xl md:text-5xl lg:text-6xl xl:text-8xl 3xl:text-9xl">
              Our Story
            </h2>
            <Readmore minHeight={108}>
              <p className="mt-6 leading-relaxed text-lg 2xl:text-xl max-w-3xl">
                Building on their background in SIGINT and software development,
                Clarity&apos;s founders saw a need for user-friendly tools to
                better meet mission needs.
              </p>
              <p className="mt-6 leading-relaxed text-lg 2xl:text-xl max-w-3xl">
                Recognizing that people are the most important part of any
                company, they invested in principles and work that empower and
                support employees and end users. They set up a positive feedback
                loop where Clarity takes care of employees and employees take
                care of the customer and the customer looks to Clarity for
                solutions. Ten years and 120 employees later, Clarity remains
                true to these values and competencies.
              </p>
            </Readmore>
          </div>
        </div>
      </BImage>
      <MissionSection />
      <LeadershipSection />
      <CreedSection />
      <PartnersSection />
    </Layout>
  );
};

export default About;
